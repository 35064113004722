import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCol,
  IonContent,
  IonFooter,
  IonGrid,
  IonIcon,
  IonLabel,
  IonPage,
  IonRow,
  IonToolbar,
} from "@ionic/react";
import { addCircleSharp } from "ionicons/icons";
import { useEffect, useState } from "react";
import BasicTextBox from "../components/BasicTextBox";
import Header from "../components/Header";
import ChaListItems from "../components/ListItems";
import Settings from "../components/Settings";
import Sidebar from "../components/Sidebar";
import myUtil, { IS_EMBED } from "../helpers/cha-utils";
import ChaBasicListType from "../types/BasicListType";


const getSavedData = () => {
  const json_data = localStorage.getItem(myUtil.prefix + "saved-items");
  if (json_data) return JSON.parse(json_data);
  else return [];
};

const Index: React.FC = () => {

  const mulitplier = 35.27396195;
  const [decimalValue, setDecimalValue] = useState<number>(5);

  const [formData, setFormData] = useState<{ [key: string]: number }>({
    kg: 1,
    oz: mulitplier,
  });

  let displayData: { [key: string]: number } = {};
  Object.entries(formData).forEach(
    ([key, value]) =>
      (displayData[key] = myUtil.prepareFloatingNumber(value, decimalValue))
  );

  const [items, setItems] = useState<ChaBasicListType[]>(getSavedData());

  useEffect(() => {
    localStorage.setItem(myUtil.prefix + "saved-items", JSON.stringify(items));
  }, [items]);

  const inputChangeHandler = (target: HTMLIonInputElement) => {
    if (!target.value) return;
    const input = target.name;
    const input_value = +target.value;
    let output = "";
    let output_value = 0;

    switch (input) {
      case "kg":
        output = "oz";
        output_value = input_value * mulitplier;
        break;
      case "oz":
        output = "kg";
        output_value = input_value / mulitplier;
        break;
    }

    setFormData((oldData) => {
      return {
        ...oldData,
        [input]: input_value,
        [output]: output_value,
      };
    });
  };

  const saveButtonHandler = () => {
    setItems((oldItems) => [
      ...oldItems,
      {
        key: Date.now(),
        a: formData["kg"].toString(),
        b: formData["oz"].toString(),
      },
    ]);
  };

  // console.log("rendered", formData);
  return (
    <>
      {!IS_EMBED && <Sidebar />}

      <IonPage id="main-content">
        <Header title="Kilograms to Ounces Converter" />
        <IonContent className="ion-padding-vertical">
          <IonGrid fixed={true}>
            <IonRow className="ion-justify-align-center">
              <IonCol size="12" sizeMd="6">
                <IonGrid>
                  <IonRow>
                    <IonCol>
                      <BasicTextBox
                        name="kg"
                        type="number"
                        label="Weight"
                        subLabel="in kilograms"
                        short="kg"
                        textSize={3}
                        value={displayData["kg"]}
                        onIonInput={inputChangeHandler}
                      />
                    </IonCol>
                  </IonRow>
                  <IonRow>
                    <IonCol>
                      <BasicTextBox
                        name="oz"
                        type="number"
                        label="Weight"
                        subLabel="in ounces"
                        short="oz"
                        textSize={3}
                        value={displayData["oz"]}
                        onIonInput={inputChangeHandler}
                      />
                    </IonCol>
                  </IonRow>
                  <IonRow>
                    <IonCol className="ion-text-right">
                      <IonButton color="primary" onClick={saveButtonHandler}>
                        <IonIcon icon={addCircleSharp} slot="start"></IonIcon>
                        <IonLabel>Save</IonLabel>
                      </IonButton>
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </IonCol>
              <IonCol size="12" sizeMd="6">
                <IonCard className="ion-no-padding">
                  <IonCardContent className="ion-no-padding">
                    <ChaListItems
                      items={items}
                      decimalValue={decimalValue}
                      onDelete={(key) =>
                        setItems((oldItems) =>
                          oldItems.filter((item) => item.key !== key)
                        )
                      }
                      onClear={() => setItems([])}
                      onListUpdate={(event) =>
                        setItems(event.detail.complete(items))
                      }
                    />
                  </IonCardContent>
                </IonCard>
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonContent>
        <IonFooter>
          <IonToolbar>
            <IonGrid fixed={true}>
              <IonRow>
                <IonCol>
                  <Settings
                    decimalValue={decimalValue}
                    onRangeUpdated={(v) => setDecimalValue(+v)}
                  ></Settings>
                </IonCol>
              </IonRow>
            </IonGrid>
          </IonToolbar>
        </IonFooter>
      </IonPage>
    </>
  );
};

export default Index;
